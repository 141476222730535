import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AlertElem from "../../components/Alert";
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { useSelector,useDispatch } from 'react-redux';
import {conversation,setConversations} from  '../../redux/conversationSlice'
import {getNotificationCounter} from '../../redux/notificationCounter'
import { useSearchParams } from 'react-router-dom';
import {messageThunk,setUser,setConversationsId,setMessages} from '../../redux/messageSlice'
import {setBarStatus} from '../../redux/messageBarSlice'
import {setStatus} from '../../redux/loaderSlice'
import Utils from "../../utils/Utils";
const { serverURL } = Utils;


export default function SideBar({socket,scrollDownHandler}) {
    const data              = useSelector(state=>state.conversation.conversations)
    const conversationsId   = useSelector(state=>state.message.conversationsId)
    const messages_         = useSelector(state=>state.message.data)
    const liveUser          = useSelector(state=>state.liveUser.liveUsers)
    const useProfileData    = useSelector(state=>state.auth.profile)
    const isSend            = useSelector(state=>state.messageBarSlice.isSend)
    const dispatch          = useDispatch()
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const thread_id = searchParams.get('thread_id')
    let chatType = searchParams.get('chatType'); 
    let chatSession = searchParams.get('chatSession');
    let [latestMessage_,setLatestMessage] = React.useState([])
    let [message,setMessage] = React.useState('')
    let [showAlertMessage,setShowAlertMessage] = React.useState('')


    const createNewConversations = async (userData) => {
        const response = await fetch(`${serverURL}/chat/conversations/`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(userData),
            credentials: "include",
        });
        if (!response.ok) throw new Error(await response.text());
        const data = await response.json();
        return data;
    }
    
    const fetchMessages = async(conversationId,user) => {
        dispatch(setStatus({status:true}))
        dispatch(getNotificationCounter(useProfileData._id))
        localStorage.setItem('thread_id_',conversationId)
        if (thread_id === conversationId ) return true
        localStorage.setItem('limit',0) 
        scrollDownHandler()
        dispatch(setMessages([]))
        if (conversationId){
            let payLoad_ = {conversationId,userId:useProfileData._id,skip:0}
            dispatch(messageThunk(payLoad_))
            dispatch(setConversationsId(conversationId))
            const params = new URLSearchParams({ thread_id:conversationId});
            navigate(`/messages?${params.toString()}`);
        } else {
            let payLoad = {senderId:useProfileData._id,receiverId:user.id}
            let response = await createNewConversations(payLoad)
            const {status} = response
            if (status === 200){
                let newConversationId  = response.conversation.conversationId
                let payLoad_ = {conversationId:newConversationId,userId:useProfileData._id,skip:0}
                dispatch(messageThunk(payLoad_))
                dispatch(setConversationsId({conversationId:newConversationId}))
                const params = new URLSearchParams({ thread_id:newConversationId});
                navigate(`/messages?${params.toString()}`);
                let userDetails = localStorage.getItem('user_details') || "{}"
                let userDetails_ = JSON.parse(userDetails)
                if (Object.keys(userDetails_).length > 0 ){
                    let userId = userDetails_._id
                    dispatch(conversation(userId));
                }
            }
        }
        dispatch(setUser({user}))


        let isSendData  = []
        if (data.length >0){
            data.map((thread)=>{
                let isSend =  true
                if (thread.user.planType === 0 && useProfileData.planType === 0){
                    isSend =  false
                } else if (thread.user.planType === 40 && useProfileData.planType === 0){
                    isSend =  false
                }
                isSendData.push({conversationId:thread.conversationId,isSend:isSend})
            })
        }
        dispatch(setBarStatus({isSend:isSendData}))
        setTimeout(() => {
            dispatch(setStatus({status:false}))
        }, 2000);
    };

    function getMediaTypeFromUrl(url) {
        if (url){
            const searchString = 'https://ykop-uploads.s3.amazonaws.com';
            return url.includes(searchString);
        } else {
            return false
        }
    }
    
    React.useEffect(()=>{
        socket?.on("sendConverstionsUser", data => {
            dispatch(setConversations({conversations:data}))
        })

        socket?.on('lastestMessage',(data)=>{
            let thread_id_ = localStorage.getItem('thread_id_') || false
            setLatestMessage(data)
            showAlertMessageHandler(data,socket,thread_id_)
        })
        
    },[socket])

    React.useEffect(()=>{
        if (chatType && chatType === 'new'){
            let receiverId = chatSession
            socket?.emit("loadUser", {receiverId});
        }
        let thread_id_ = thread_id
        localStorage.setItem('thread_id_',thread_id_)
        if (thread_id_){
            let flilterData = data.find((threadData)=>threadData.conversationId == thread_id_)
            if (flilterData){
                const {user} = flilterData
                dispatch(setUser({user}))
            }
        }
    },[chatType])

    const showAlertMessageHandler = (data,socket,thread_id_) =>{
        onClose()
        
        let filterData = data.filter((messageThread)=> messageThread.notifiy_seen === false && messageThread.receiverId_ == useProfileData._id)
        if (thread_id_){
            filterData = data.filter((messageThread)=>messageThread.conversationId != thread_id_ && messageThread.notifiy_seen === false && messageThread.receiverId_ == useProfileData._id)
        }
        
        if (filterData.length>0){    
            let messageIds = []
            filterData.map((messageThread_)=>{
                messageIds.push(messageThread_.id)
                let userName = messageThread_.user.username || 'User'
                setShowAlertMessage(true)
                setMessage(`${userName.charAt(0).toUpperCase() + userName.slice(1)} send the message`);
                setTimeout(() => {
                    setShowAlertMessage(false)
                    setMessage('')
                }, 1000);
            }) 
            if (messageIds.length>0){
                socket?.emit("messageNotification",{messageIds});
            }   
        }
        
    }

    const onClose = () => {
        setShowAlertMessage(false)
    }

    React.useEffect(()=>{
        let thread_id_ = localStorage.getItem('thread_id_') || false
        if (thread_id_){
            let flilterData = data.find((threadData)=>threadData.conversationId == thread_id_)
            if (flilterData){
                const {user} = flilterData
                dispatch(setUser({user}))
            }
        }
    },[data])

    return (
        <>
            <List sx={{paddingTop:"0px"}} component="nav" className='user_nav_list' aria-label="main mailbox folders">
                { data.map(({conversationId,user,latestMessage,unreadCount,is_block,blocked_id},index)=>{
        
                    let profileUrl_ = ""
                    let latest_message = latestMessage
                    let unreadCount_  = unreadCount
                    let isLive =  false
                    let liveUser_ =  liveUser.filter(userData=>{return userData.userId == user.id})
                    if (liveUser_.length > 0){isLive = true}    
                    let isActiveTab = ''
                    

                    if (thread_id != null &&  conversationId != null){
                        if (thread_id === conversationId){
                            isActiveTab = 'active user_list'
                        } else {
                            isActiveTab = ''
                        }
                    }
                    let filterMessages = latestMessage_.filter(userData=>{
                        return userData.conversationId === conversationId
                    })

                    if (filterMessages.length > 0){
                        latest_message = filterMessages[0].latestMessage
                    }

                    let messageType =  getMediaTypeFromUrl(latest_message);

                    let filterMessagesCounter = latestMessage_.filter(userData=>{  ;
                        return userData.conversationId === conversationId
                    })
                    if (filterMessagesCounter.length > 0){
                        unreadCount_ = filterMessages[0].unreadCount
                    }
                    

                    if (user?.profilePic?.profilePic){
                        profileUrl_ = user.profilePic.profilePic.url
                    }

                    let messageStatus = isSend.find((threadData)=>threadData.conversationId === conversationId)
                    
                    let isRestrictReadMsg = false
                    if (messageStatus){
                        isRestrictReadMsg = messageStatus.isSend
                    }
                
                    return (
                        <>
                        { 
                            is_block && useProfileData._id === blocked_id ?
                            <></>
                            :
                            <Box key={index} className={`${isActiveTab} user_list`}>
                                <ListItemButton
                                    onClick={() => fetchMessages(conversationId,user,profileUrl_)}
                                >   
                                    <ListItemAvatar>
                                        <Badge color="secondary" variant="dot" invisible={!isLive}>
                                            <Avatar alt={user.username} src={profileUrl_} />
                                        </Badge>
                                    </ListItemAvatar>
                                    
                                    <ListItemText
                                        primary={user.username}
                                        className='latestMsg'
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{ display: 'inline',textTransform:"capitalize"}}
                                                    component="span"
                                                    variant="body2"
                                                    id="latestMsg"
                                                    color="text.primary"
                                                >
                                                {isRestrictReadMsg ?
                                                    <>
                                                        { messageType?
                                                            "New file"
                                                            :
                                                            latest_message
                                                        } 
                                                    </>
                                                    :
                                                    'Unread message'
                                                }
                                                </Typography>
                                                {unreadCount_ !== 0 &&
                                                    <Box id="unReadCount">
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            id="msgCount"
                                                            color="text.primary"
                                                        >
                                                        {unreadCount_}
                                                        </Typography>
                                                    </Box>
                                                }
                                            </React.Fragment>
                                        }
                                    />
                                </ListItemButton>
                                <Divider/>
                            </Box>
                        }
                        </>

                    )
                })}
            </List>
            <AlertElem isAlertMessage={showAlertMessage} alertMessage={message} alertMessageType={'success'} onClose={()=>{onClose()}}/>
        </>
    );
}
