import React from 'react';
import { Box, Grid,Paper, Typography } from '@mui/material';


const Cancel = () => {
    return (
        <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid item xs={6}>
                <Paper>
                    <Box sx={{p:4}}>
                        <Typography textAlign={"center"}>
                            Payment is unsuccessfully
                        </Typography>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
    
}


export default Cancel;