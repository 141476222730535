import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Chip,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Utils from '../../utils/Utils';
import ModalMessage from '../commons/ModalMessage';
const { serverURL } = Utils;

export default function LabelSelection({
    profile,
    t,
    selectedLabels,
    setSelectedLabels,
    updateLabels,
}) {
    const [labelModal, setLabelModal] = useState(false);
    const [labels, setLabels] = useState(null);

    const handleClick = (e) => {
        e.stopPropagation();
        setLabelModal((prev) => !prev);
    };
    const handleChipClick = (category, item) => {
        setSelectedLabels((prev) => {
            if (prev[category]?.find((i) => i === item)) {
                return {
                    ...prev,
                    [category]: prev[category].filter((i) => i !== item),
                };
            } else {
                return {
                    ...prev,
                    [category]: prev[category]
                        ? [...prev[category], item]
                        : [item],
                };
            }
        });
    };
    useEffect(() => {
        if (!profile?.username) return;
        let ignore = false;
        async function getLabels() {
            try {
                let res = await fetch(`${serverURL}/user/labels`, {
                    credentials: 'include',
                });
                res = await res.json();
                setLabels(res);
            } catch (error) {
                toast.error(t('common:failedToLoad'));
            }
        }
        getLabels();
        return () => {
            ignore = true;
        };
    }, [t, profile]);
    return (
        <>
            <Accordion
                className="my-1"
                sx={{
                    backgroundColor: 'transparent',
                    backgroundImage: 'unset',
                    boxShadow: 'unset',
                    '&:before': {
                        backgroundColor: 'transparent',
                    },
                }}
            >
                <AccordionSummary
                    sx={{ padding: 0 }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="flex-row-reverse"
                >
                    <Box className="flex w-full justify-between">
                        <Typography variant="h5">{t('yourLabels')}</Typography>
                        <Button
                            variant="outlined"
                            color="inherit"
                            onClick={handleClick}
                        >
                            {t('common:edit')}
                        </Button>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {selectedLabels &&
                        labels &&
                        Object.keys(selectedLabels).map((category) => (
                            <Box key={category}>
                                <Box className="flex flex-row items-center my-2">
                                    <Typography
                                        variant="h5"
                                        className="whitespace-nowrap"
                                    >
                                        {labels.categories[category]}
                                    </Typography>
                                    <hr className="ml-2 inline-block w-full border-gray-500/50" />
                                </Box>
                                <Box className="flex gap-1 flex-wrap">
                                    {selectedLabels[category].map((item) => (
                                        <Chip
                                            key={item}
                                            label={
                                                labels.translations[category][
                                                    item
                                                ]
                                            }
                                            variant="filled"
                                        />
                                    ))}
                                </Box>
                            </Box>
                        ))}
                </AccordionDetails>
            </Accordion>
            <ModalMessage
                showModal={labelModal}
                handleCloseModal={() => setLabelModal(false)}
            >
                {labels &&
                    Object.keys(labels.categories).map((category) => (
                        <Box key={category}>
                            <Box className="flex flex-row items-center my-2">
                                <Typography
                                    variant="h5"
                                    className="whitespace-nowrap"
                                >
                                    {labels.categories[category]}
                                </Typography>
                                <hr className="ml-2 inline-block w-full border-gray-500/50" />
                            </Box>
                            <Box className="flex gap-1 flex-wrap">
                                {Object.keys(labels.translations[category]).map(
                                    (item) => (
                                        <Chip
                                            key={item}
                                            label={
                                                labels.translations[category][
                                                    item
                                                ]
                                            }
                                            variant={
                                                selectedLabels[category]?.find(
                                                    (i) => i === item
                                                )
                                                    ? 'filled'
                                                    : 'outlined'
                                            }
                                            onClick={() =>
                                                handleChipClick(category, item)
                                            }
                                        />
                                    )
                                )}
                            </Box>
                        </Box>
                    ))}
                {labels && (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            type="button"
                            color="success"
                            variant="contained"
                            sx={{ borderRadius: 10, mt: 2 }}
                            onClick={updateLabels}
                        >
                            {t('common:save')}
                        </Button>
                    </Box>
                )}
            </ModalMessage>
        </>
    );
}
