import { SvgIcon } from "@mui/material";

export default function ActivePlanIcon({ sx,theme }) {
  return (
    <SvgIcon sx={sx}>
      <svg 
        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-yjh5ow-MuiSvgIcon-root" 
        focusable="false" 
        aria-hidden="true" 
        viewBox="0 0 24 24" 
        data-testid="PaymentIcon"
    >
        <path 
            d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"
            fill={theme.palette.primary.main}
        >

        </path>
      </svg>
    </SvgIcon>
  );
}
