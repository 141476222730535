import AddIcon from "@mui/icons-material/Add";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import CheckIcon from "@mui/icons-material/Check";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import TelegramIcon from "@mui/icons-material/Telegram";
import { Box, Button, Grid, Typography} from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InputEmoji from "react-input-emoji";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import VideoThumbnail from "react-video-thumbnail";
import AlertElem from "../../components/Alert";
import User from '../../components/User';
import Skeleton from '@mui/material/Skeleton';
import UserAvatar from "../../components/UserAvatar";
import { setConversations } from "../../redux/conversationSlice";
import { setMessageIds } from "../../redux/messageRead";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { messageThunk, setMessages,setUser } from "../../redux/messageSlice";
import {showSideBar,showThread} from '../../redux/mobileChatSlice'
import {setStatus} from '../../redux/loaderSlice'
import Utils from "../../utils/Utils";
import Nodes from "../../utils/Nodes";
import CuModel from "../../components/CuModel";
const { serverURL } = Utils;
const { updateIsBlockStatus } = Nodes;


export default function Chat({ socket, scrollDown, openViewModel}) {
  let messages_ = useSelector((state) => state.message.data);
  const receiverProfile = useSelector((state) => state.message.user);
  const liveUser = useSelector((state) => state.liveUser.liveUsers);
  const messageReadIds = useSelector((state) => state.messageRead.ids);
  const conversationsId = useSelector((state) => state.message.conversationsId);
  const conversationsData = useSelector((state) => state.conversation.conversations);
  const messageBarStatus = useSelector((state) => state.messageBarSlice.isSend);
  const loader = useSelector((state) => state.loaderSlice.status);
  const userProfile = useSelector((state) => state.auth.profile);
  const [message, setMessage] = useState("");
  const [sendMsgIsRead, setSendMsgIsRead] = useState(false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  let thread_id = searchParams.get("thread_id");
  const scrollableDivRef = useRef(null);
  const messageRefs = useRef([]);
  const chatEndRef = useRef(null);
  const [nowData, setNowDate] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [loading, setLoading] = useState(true);
  const [fileName, setFileName] = useState('');
  const [showFileUploadStatus, setFileUploadStatus] = useState(false);
  const [showFileUploadError, setFileUploadError] = useState(false);
  const [progress, setProgress] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [alertMessageType,setAlertMessageType] = useState('')
  const { t, i18n } = useTranslation(["chat"]);
  const [showUserBlockModel,setShowUserBlockModel] = useState(false)
  const matches = useMediaQuery('(max-width:899px)');
  const navigate          = useNavigate();
  const [userMessagePlanType,setUserMessagePlanType] = useState(60)
  const [isThreadBlocked, setThreadBlocked] = useState(false)
  const isSend  = useSelector(state=>state.messageBarSlice.isSend)

  useEffect(() => {
    let receiverId = receiverProfile.id;
    let senderId = userProfile._id;
    let messagesData = messages_.filter((data_) => {
      return data_.conversationId === thread_id;
    });
    if (messagesData.length > 0) {
      let messages = messagesData[0].data;
      let receverMessage = messages.filter(
        (message) =>
          message.user.id !== senderId && message.conversationId == thread_id,
        message.is_read === false
      );
      let messageIds = receverMessage.map((message) => message.id);
      let messageStatus = isSend.find((threadData)=>threadData.conversationId === thread_id)
      if (messageStatus){
        if (messageStatus.isSend){
          socket?.emit("readMessage", { senderId, receiverId, messageIds });
        }
      }
      
    }

    const handleMessagesRead = (data) => {
      dispatch(setMessageIds(data));
    };
    socket?.on("messagesRead", handleMessagesRead);
  }, [socket, messages_, liveUser]);

  useEffect(() => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const formattedTime = `${hours % 12 || 12}:${minutes
      .toString()
      .padStart(2, "0")} ${hours >= 12 ? "PM" : "AM"}`;
    setNowDate(formattedTime);
  }, []);

  useEffect(() => {
    let profilePic_ =
      "https://img.freepik.com/free-psd/3d-illustration-human-avatar-profile_23-2150671142.jpg?size=626&ext=jpg";
    if (receiverProfile?.profilePic?.profilePic?.url) {
      profilePic_ = receiverProfile?.profilePic?.profilePic?.url;
    }

    setProfilePic(profilePic_);
  }, [receiverProfile]);

  useEffect(() => {
    if (scrollDown && scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({
        top: scrollableDivRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [scrollDown]);

  useEffect(() => {
    socket?.on("getMessage", (data) => {
      const exist_Data = Object.isExtensible(messages_)
        ? messages_
        : [...messages_];
      let updatedData = updateConversationData(exist_Data, data);
      dispatch(setMessages(updatedData));
      smoothScrollToBottom()
    });
    
  }, [socket, messages_]);

  function updateConversationData(exist_Data, new_data) {
    const updatedConversations = exist_Data.map((conversation) => {
      if (conversation.conversationId === new_data.conversationId) {
        return {
          ...conversation,
          data: [
            ...conversation.data,
            ...new_data.data.filter(
              (newMsg) =>
                !conversation.data.some(
                  (existingMsg) => existingMsg.id === newMsg.id
                )
            ),
          ],
        };
      }
      return conversation;
    });
    const conversationExists = updatedConversations.some(
      (conv) => conv.conversationId === new_data.conversationId
    );
    if (!conversationExists) {
      updatedConversations.push({ ...new_data });
    }

    return updatedConversations;
  }

  useEffect(() => {
    let receiverId = receiverProfile.id;
    let senderId = userProfile._id;
    let messagesData = messages_.filter((data_) => {
      return data_.conversationId === thread_id;
    });
    if (messagesData.length > 0) {
      let messages = messagesData[0].data;
      let receverMessage = messages.filter(
        (message) =>
          message.user.id !== senderId && message.conversationId == thread_id
      );
      let messageIds = receverMessage.map((message) => message.id);
      const handleMessagesRead = (data) => {
        dispatch(setMessageIds(data));
      };
      socket?.on("messagesRead", handleMessagesRead);
      const interval = setInterval(() => {
        if (thread_id === conversationsId) {
          let messageStatus = isSend.find((threadData)=>threadData.conversationId === thread_id)
          if (messageStatus){
            if (messageStatus.isSend){
              socket?.emit("readMessage", { senderId, receiverId, messageIds });
            }
            
          }
          
        }
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [conversationsId, message, messages_]);

  useEffect(() => {
    messageRefs.current = messageRefs.current.slice(0, messages_.length);
  }, [messages_]);


  const smoothScrollToBottom = (duration = 1000) => {
    if (!scrollableDivRef.current || !chatEndRef.current) return;
    const chatWindow = scrollableDivRef.current;
    const targetPosition = chatEndRef.current.offsetTop;
    const startPosition = chatWindow.scrollTop;
    const distance = targetPosition - startPosition;
    let startTime = null;
    function animation(currentTime) {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = ease(timeElapsed, startPosition, distance, duration);
      chatWindow.scrollTop = run; // Scroll the chat window
      if (timeElapsed < duration) requestAnimationFrame(animation);
    }
    function ease(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    }
    requestAnimationFrame(animation);
  };
  
  useEffect(() => {
    localStorage.setItem('limit',0) 
    if (thread_id) {
      let payLoad_ = { conversationId: thread_id, userId: userProfile._id,skip:0 };
      dispatch(messageThunk(payLoad_)).then(() => {
        smoothScrollToBottom(2000)
      })
    }
  }, [thread_id]);


  const messageChangeHandler = (e) => {
    setMessage(e);
  };

  const messageSendHandler = async() => {
    
    let senderId = userProfile._id;
    const {id}  = receiverProfile
    socket?.emit("checkPlanStatus", { senderId,receiverId:id,conversationsId:thread_id});

    let response =  true

    async function checkPlanStatus(socket) {
      return new Promise((resolve, reject) => {
        try {
          socket?.on("checkPlanStatusSignal", (status) => {
            if (status && status.planType === 0) {
              setUserMessagePlanType(0);
              setAlertMessageType('error');
              setErrorMessage("Messages can't be sent as you're now a free user.");
              setFileUploadError(true);
              resolve(false);
            } if (status && status.planType === 1) {
              setUserMessagePlanType(1);
              setAlertMessageType('error');
              setErrorMessage("Messages can't be sent as you're now a free user.");
              setFileUploadError(true);
              resolve(false);
            } else {
              resolve(true);
            }
          });
        } catch (error) {
          resolve('end')
        }
      });
    }
    let socketResponse = await checkPlanStatus(socket)
    if (socketResponse == 'end'){
      response = true
    } else {
      response = socketResponse
    }
    

    if (response){
      socket?.emit("sideBarUsers", { senderId });
      const handleSideBarUsers = (data) => {
        if (data?.data.length > 0) {
          dispatch(setConversations({ conversations: data.data }));
        }
      };
      socket?.on("setSideBarUsers", handleSideBarUsers);
      if (message !== "") {
        let updatedConversationsData = conversationsData.map((conversation) => {
          if (conversation.conversationId === thread_id) {
            return { ...conversation, latestMessage: message };
          }
          return conversation;
        });

        dispatch(setConversations({ conversations: updatedConversationsData }));

        setSendMsgIsRead(true);
        let receiverId = receiverProfile.id;
        let senderId = userProfile._id;
        socket.emit("sendMessage", {
          receiverId,
          senderId,
          conversationsId: thread_id,
          message,
        });
        setMessage("");
        setTimeout(()=>{
          scrollToBottom()
        },3000)
        
      }
    }
  };

  const handleKeyDown = (e) => {
    messageSendHandler();
  };

  const fileUpload = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.style.display = "none";
    input.addEventListener("change", (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const fileName = selectedFile.name; 
        setFileName(fileName)
        setFileUploadStatus(true)
        handleFileUpload(
          selectedFile,
          receiverProfile.id,
          userProfile._id,
          thread_id
        );
      }
    });

    document.body.appendChild(input);
    input.click();
    document.body.removeChild(input);
    window.scrollTo(0, document.body.scrollHeight);
  };

  const handleFileUpload = (file, receiverId, senderId, conversationsId) => {
    const formData = new FormData();
    
    const receiverLiveUserData = liveUser.find(user => user.userId === receiverId);
    const senderLiveUserData = liveUser.find(user => user.userId === userProfile._id);
  
    if (receiverLiveUserData) {
      formData.append("receiverSocketId", receiverLiveUserData.socketId);
    }
  
    if (senderLiveUserData) {
      formData.append("senderSocketId", senderLiveUserData.socketId);
    }
  
    formData.append("file", file);
    formData.append("senderId", senderId);
    formData.append("conversationId", conversationsId);
  
    const xhr = new XMLHttpRequest();

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        setProgress(percentCompleted);
      }
    };
  
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 413) {
          openAlertMesssage('File size should be less then or equal to 500MB.','error')
        } else if (xhr.status === 200) {
          openAlertMesssage('File uploaded successfully.','success')
        } else {
          openAlertMesssage('Something went wrong please try again later.','error')
        }
      }
    };
  
    xhr.onerror = () => {
      openAlertMesssage('Something went wrong please try again later.','error')
    };
  
    xhr.open("POST", `${serverURL}/chat/upload`, true);
    xhr.send(formData);

    setTimeout(() => {
      scrollToBottom();
    }, 3000);
    
  };

  const openAlertMesssage = (message,messageType) =>{
    setAlertMessageType(messageType)
    setFileUploadStatus(false);
    setFileUploadError(true);
    setErrorMessage(message)
    setTimeout(() => {
      setErrorMessage('')
      setFileUploadError(false);
    }, 6000);
  }

  const getFileNameFromUrl = (url) => {
    try {
      const urlObject = new URL(url);
      const pathname = urlObject.pathname;
      return pathname.substring(pathname.lastIndexOf("/") + 1);
    } catch (error) {
      return "Not Available";
    }
  };

  const getMediaTypeFromUrl = (url) => {
    const extension = url.split(".").pop().split(/[\?#]/)[0];
    const mediaTypes = {
      jpg: "image",
      jpeg: "image",
      png: "image",
      gif: "image",
      webp: "image",
      svg: "image",
      pdf: "file",
      txt: "file",
      css: "file",
      js: "file",
      html: "file",
      docx:"file",
      ppk:"file",
      json: "file",
      mp3: "audio",
      wav: "audio",
      ogg: "audio",
      flac: "audio",
      aac: "audio",
      mp4: "video",
      webm: "video",
      mov: "video",
      avi: "video",
      mkv: "video",
      m4a: "video",
      aiff: "audio",
      m3u: "audio",
      pls: "audio",
      dll: "file",
    };
    return mediaTypes[extension] || "message";
  };

  const getDateLabel = (date) => {
    const today = new Date();
    const messageDate = new Date(date);
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (messageDate.toDateString() === today.toDateString()) {
      return "Today";
    }
    if (messageDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    }
    return messageDate.toDateString();
  };

  const groupMessagesByDate = (messages) => {
    if (messages.length > 0) {
      let messagesData = messages.filter((data_) => {
        return data_.conversationId === thread_id;
      });
      if (messagesData.length > 0) {
        messages = messagesData[0].data;
        return messages.reduce((groups, message) => {
          const dateLabel = getDateLabel(message.createdAt);
          if (!groups[dateLabel]) {
            groups[dateLabel] = [];
          }
          groups[dateLabel].push(message);
          return groups;
        }, {});
      }
    }
  };

  const groupedMessages = groupMessagesByDate(messages_);

  const formatDate = (date) => {
    const options = { hour: "2-digit", minute: "2-digit" };
    return new Date(date).toLocaleTimeString([], options);
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleImageError = (e) => {
    e.target.onerror = null; 
    e.target.style.display = "none"; 
    e.target.nextElementSibling.style.display = "block"; 
  };

  const onClose = () => {
    setFileUploadError(false)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" className="upload_per" sx={{ color: 'text.secondary' }}>
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  const fetchMessages = async () => {
    const container = scrollableDivRef.current;
    try {;  
      let skip_ = parseInt(localStorage.getItem('limit'))
      localStorage.setItem('limit',skip_+15)
      let skip = parseInt(localStorage.getItem('limit'))
      let payLoad_ = { conversationId: thread_id, userId: userProfile._id,skip:skip };
      let scrollHeightBeforeLoading = container.scrollHeight;
      dispatch(messageThunk(payLoad_)).then(() => {
        const scrollHeightAfterLoading = container.scrollHeight;
        container.scrollTop = scrollHeightAfterLoading - scrollHeightBeforeLoading;
      });;
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
    setLoading(false);
  };

  const handleScroll = () => {
    if (scrollableDivRef.current.scrollTop === 0) {
      fetchMessages()
    }
  };

  useEffect(() => {
    const container = scrollableDivRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const backHandler = () =>{
    dispatch(showSideBar({show_sidebar:true}))
    dispatch(showThread({show_thread:false}))
    navigate('/messages')

  }

  const showUserBlockModelHandler = () =>{
    setShowUserBlockModel(true)
  }

  const hideUserBlockModelHandler = () =>{
    setShowUserBlockModel(false)
  }

  const userBlockModelHandler = async() =>{
    let response = await updateIsBlockStatus(thread_id,userProfile._id)
    if (response){
      hideUserBlockModelHandler()
      let conversationsData_ = [...conversationsData];
      const index = conversationsData_.findIndex(data => data.conversationId === thread_id);
      if (index >-1){
        navigate('/messages')
        conversationsData_.splice(index,1)
        dispatch(setConversations({ conversations: conversationsData_}));

        let senderId = userProfile._id;
        let filterData = conversationsData.find((data)=>data.conversationId === thread_id)
        if (filterData){
          let receiverId = filterData.user.id
          socket?.emit("blockUser", { senderId, receiverId});
        }
        const blockUser = (data)=>{
          if (data.status){
            navigate('/messages')
          }
        }
        socket?.on("blockUserSignal", blockUser);

      }
    }
  }

  useEffect(()=>{
    let msgPlanType  =  userProfile.planType
    let findData = messages_.find((data)=>data.conversationId === thread_id )
    if (findData){
      const lastObject = findData.data[findData.data.length - 1];
      if (lastObject){
        if (lastObject.user.id !== userProfile._id){
          const {user} = lastObject
          if (user.planType === 60 && msgPlanType === 0){
            msgPlanType = 60
          } else if (user.planType === 40 && msgPlanType === 0){
            msgPlanType = 1
          } else if (user.planType === 0 && msgPlanType === 40){
            msgPlanType = 40
          } else if (user.planType == 0 && msgPlanType == 0){
            msgPlanType = 1
          } else if  (user.planType === 0 && msgPlanType === 60){
            msgPlanType = 60
          } else if  (user.planType === 60 && msgPlanType === 60){
            msgPlanType = 60
          } else if  (user.planType === 40 && msgPlanType === 40){
            msgPlanType = 40
          } else if  (user.planType === 40 && msgPlanType === 60){
            msgPlanType = 60
          }  else if  (user.planType === 60 && msgPlanType === 40){
            msgPlanType = 40
          }
        } else {
          const {planType} = receiverProfile
          if (planType !== undefined){
            if (planType == 0 && msgPlanType == 0){
              msgPlanType = 1
            } else if(planType == 40 && msgPlanType == 0){
              msgPlanType = 1
            } else if (planType == 60 && msgPlanType == 0){
              msgPlanType = 60
            } else if (planType == 0 && msgPlanType == 60){
              msgPlanType = 60
            } else if (planType == 0 && msgPlanType == 40){
              msgPlanType = 40
            } else if (planType == 40 && msgPlanType == 40){
              msgPlanType = 40
            }  else if (planType == 60 && msgPlanType == 60){
              msgPlanType = 60
            }  else if (planType == 60 && msgPlanType == 40){
              msgPlanType = 40
            } else if (planType == 40 && msgPlanType == 60){
              msgPlanType = 60
            }
          } else {
            msgPlanType = 60
          }        
        }
      } else {
        if (msgPlanType === 0 && receiverProfile.planType == 40) {
          msgPlanType = 1
        } else if (msgPlanType === 0 && receiverProfile.planType == 0){
          msgPlanType = 1
        }
      }
    }
    setUserMessagePlanType(msgPlanType)
    setTimeout(() => {
      dispatch(setStatus({status:false}))
    }, 2000);
    
  },[messages_])

  useEffect(()=>{
    let filterData = messages_.find((data)=>data.conversationId === thread_id)
    if (filterData){
      setThreadBlocked(filterData.is_block)
    } else {
      setThreadBlocked(true)
    }
    
  },[conversationsData,thread_id,messages_])

  useEffect(()=>{
    let thread_id_ = localStorage.getItem('thread_id_') || false
    if (thread_id_){
        let flilterData = conversationsData.find((threadData)=>threadData.conversationId == thread_id_)
        if (flilterData){
            const {user} = flilterData
            dispatch(setUser({user}))
        }
    }
  },[conversationsData])


  return (
    <>
      <Grid container>
        <Grid item xs={12} className="chat_header">
          <Box p={2}>
            <Grid container alignItems={"center"}>
              <Grid item xs={6}>
                <Grid container alignItems={"center"}>
                  {matches &&
                    <Grid item xs={2}>
                      <ArrowBackIosIcon onClick={()=>backHandler()}/>
                    </Grid>
                  }
                  <Grid item xs={3} md={2}>
                    <UserAvatar userId={receiverProfile.id} user={{...receiverProfile, profilePic: receiverProfile?.profilePic?.profilePic}} username={receiverProfile.username} />
                  </Grid>
                  <Grid item md={6}>
                    {receiverProfile.deletedAccount ? (
                      <Typography>
                        {t("deletedUser")}
                      </Typography>
                    ) : (
                      <User showMessage={false} username={receiverProfile.username} pageType={'feed'} posts={{authorID:receiverProfile?.id}} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5} textAlign={"end"}>
                {isThreadBlocked?
                <Box sx={{cursor:"pointer"}}>
                  Blocked
                </Box>
                :
                <Box sx={{cursor:"pointer"}} onClick={()=>{showUserBlockModelHandler()}}>
                  Hide/Block
                </Box>
                }
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          className="chat"
          ref={scrollableDivRef}
          height={"480px"}
          sx={{ overflowY: "auto" }}
        >
          {loader ? 
            <Grid container spacing={2} sx={{alignItems:'center',justifyContent:'center',height:'350px',padding:{xs:"20px",sm:'0px'}}}>
              <Grid item  xs={11} mt={1}>
                <Skeleton variant="rounded" width={300} height={70} />
              </Grid>
              <Grid item xs={11} textAlign='-webkit-right'>
                <Skeleton variant="rounded" width={210} height={60} />
              </Grid>
              <Grid item xs={11}>
                <Skeleton variant="rounded" width={210} height={55} />
              </Grid>
              <Grid item xs={11} textAlign='-webkit-right'>
                <Skeleton variant="rounded" width={270} height={70} />
              </Grid>
              <Grid item xs={11}>
                <Skeleton variant="rounded" width={250} height={60} />
              </Grid>
              <Grid item xs={11} textAlign='-webkit-right'>
                <Skeleton variant="rounded" width={300} height={55} />
              </Grid>
            </Grid>
            :
            <>
              { userMessagePlanType === 1 ?
                <Grid container sx={{alignItems:'center',justifyContent:'center',height:'350px',padding:{xs:"20px",sm:'0px'}}}>
                  <Grid item={12}>
                    <Typography className="upgrade_message">
                      Please upgrade to read and reply to this member's message
                    </Typography>
                  </Grid>
                </Grid>
                :
                <Box p={3.2}>
                  {groupedMessages && Object.keys(groupedMessages).length > 0 ? (
                    Object.keys(groupedMessages).map((date,index) => {
                      return (
                        <Box key={date}>
                          {date !== "Invalid Date" && (
                            <Box
                              variant="caption"
                              sx={{
                                marginTop: "20px",
                                marginBottom: "28px",
                                textAlign: "center",
                              }}
                            >
                              <Typography className="message_group" component={"span"}>
                                {date}
                              </Typography>
                            </Box>
                          )}
                          {groupedMessages[date].map((message, index) => {
                            let messageType = getMediaTypeFromUrl(message.message);
                            let filterIds = messageReadIds.filter(
                              (message_) => message_.id === message.id
                            );
                            let is_read = message.is_read;
                            if (filterIds.length > 0) {
                              is_read = filterIds[0].is_read;
                            }
                            let userId = message.user.id;
                            let name = message.message;
                            if (messageType !== "message") {
                              name = decodeURIComponent(
                                getFileNameFromUrl(message.message)
                              );
                            }
                            return (
                              <Box
                                key={index}
                                ref={(el) => (messageRefs.current[index] = el)}
                              >
                                {thread_id === message.conversationId && (
                                  <Box sx={{ marginBottom: "8px" }}>
                                    {userId === userProfile?._id ? (
                                      <Box
                                        sx={{
                                          height: "auto",
                                          minHeight: "70px",
                                          width: "265px",
                                          marginLeft: "auto",
                                          position: "relative",
                                        }}
                                      >
                                        <Grid container>
                                          <Grid
                                            item
                                            className="chat_send"
                                            textAlign={"left"}
                                          >
                                            {messageType === "message" ? (
                                              <Typography textAlign={"end"}>
                                                {message.message}
                                              </Typography>
                                            ) : (
                                              <Box
                                                display={"flex"}
                                                alignItems={"center"}
                                                style={{
                                                  maxWidth: "100%",
                                                  padding: "10px",
                                                  border: "1px solid #ccc",
                                                  borderRadius: "8px",
                                                  backgroundColor: "#f9f9f9",
                                                }}
                                              >
                                                {messageType === "image" ? (
                                                  <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    flex="1"
                                                    position="relative"
                                                    sx={{cursor:"pointer"}}
                                                    onClick={() => {
                                                      openViewModel(message.message,'image',name);
                                                    }}
                                                  >
                                                    <img
                                                      src={message.message}
                                                      alt={name}
                                                      style={{
                                                        width: "100%",
                                                        height: "auto",
                                                        maxHeight: "150px",
                                                        display: "block", 
                                                        borderRadius: "8px",
                                                        objectFit: "contain",
                                                        maxWidth: "200px",
                                                        padding: "10px",
                                                      }}
                                                      onError={handleImageError}
                                                    />
                                                    <Typography
                                                      style={{
                                                        display: "none",
                                                        textAlign: "center",
                                                        width: "100%",
                                                        color: "#888",
                                                      }}
                                                    >
                                                      Not Available
                                                    </Typography>
                                                  </Box>
                                                ) : messageType === "video" ? (
                                                  <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    margin={"10px 0px 0px 0px"}
                                                    style={{
                                                      width: "100%",
                                                      maxWidth: "100%",
                                                      padding: "10px",
                                                      border: "1px solid #ccc",
                                                      borderRadius: "8px",
                                                      backgroundColor: "#f9f9f9",
                                                    }}
                                                  >
                                                    <Typography
                                                      style={{
                                                        width: "100%",
                                                        cursor: "pointer",
                                                        color: "#007bff",
                                                        textDecoration: "underline",
                                                      }}
                                                      onClick={() => {
                                                        openViewModel(message.message,'video',name);
                                                      }}
                                                    >
                                                      <VideoThumbnail
                                                        videoUrl={message.message}
                                                        thumbnailHandler={(
                                                          thumbnail
                                                        ) => {
                                                          setLoading(false);
                                                        }}
                                                      />
                                                      {!loading && (
                                                        <div
                                                          style={{
                                                            position: "absolute",
                                                            top: "50%",
                                                            left: "45%",
                                                            transform:
                                                              "translate(-50%, -50%)",
                                                            cursor: "pointer",
                                                            width: "48px",
                                                            height: "48px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            backgroundColor:
                                                              "rgba(0, 0, 0, 0.6)",
                                                            borderRadius: "50%",
                                                          }}
                                                          onClick={() =>
                                                            openViewModel(message.message,'video',name)
                                                          }
                                                        >
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            fill="white"
                                                            width="24"
                                                            height="24"
                                                          >
                                                            <path d="M8 5v14l11-7z" />
                                                          </svg>
                                                        </div>
                                                      )}
                                                    </Typography>
                                                  </Box>
                                                ) : (
                                                  <Typography 
                                                    textAlign={"end"} color={"#000"}
                                                    onClick={() => {
                                                      openViewModel(message.message,'file',name);
                                                    }}
                                                    sx={{
                                                      cursor:"pointer",
                                                      overflow:"hidden",
                                                      textOverflow:"ellipsis",
                                                      whiteSpace:"nowrap"
                                                    }}
                                                  >
                                                    {name}
                                                  </Typography>
                                                )}
                                              </Box>
                                            )}
                                            <Box height={"16px"} textAlign={"end"}>
                                              {is_read ? (
                                                <span>
                                                  <DoneAllIcon
                                                    sx={{ color: "#000" }}
                                                    fontSize="16px"
                                                  />
                                                </span>
                                              ) : (
                                                <span>
                                                  <CheckIcon
                                                    sx={{ color: "#000" }}
                                                    fontSize="16px"
                                                  />
                                                </span>
                                              )}
                                              <Typography
                                                variant="caption"
                                                sx={{
                                                  textAlign: "left",
                                                  color: "#000",
                                                  marginLeft: "10px",
                                                }}
                                              >
                                                {message.createdAt
                                                  ? formatDate(message.createdAt)
                                                  : nowData}
                                              </Typography>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    ) : (
                                      <Box
                                        sx={{
                                          wordBreak: "break-word",
                                          lineBreak: "anywhere",
                                          height: "auto",
                                          minHeight: "70px",
                                          width: "241px",
                                          background: "#5d6c7b",
                                          borderRadius: "0px 10px 10px 10px",
                                          overflow: "hidden",
                                          padding: "10px",
                                        }}
                                        margin={"10px 0px"}
                                        position={"relative"}
                                      >
                                        {messageType === "message" ? (
                                          <Typography
                                            className="chat_receiver"
                                            textAlign={"left"}
                                          >
                                            {name}
                                          </Typography>
                                        ) : (
                                          <Box
                                            display={"flex"}
                                            justifyContent={"space-between"}
                                            alignItems={"center"}
                                            width={"100%"}
                                            borderRadius={"8px"}
                                            style={{ backgroundColor: "#f9f9f9" }}
                                            padding={"10px"}
                                          >
                                            {messageType === "image" ? (
                                              <>
                                                <img
                                                  src={message.message}
                                                  alt={name}
                                                  style={{
                                                    width: "100%",
                                                    height: "auto", 
                                                    maxHeight: "150px",
                                                    display: "block",
                                                    borderRadius: "8px",
                                                    objectFit: "contain",
                                                    maxWidth: "200px",
                                                    margin: "0 auto",
                                                    padding: "10px",
                                                  }}
                                                  onClick={() => {
                                                    openViewModel(message.message,'image',name);
                                                  }}
                                                  onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.style.display = "none";
                                                    e.target.nextElementSibling.style.display ="block";
                                                  }}
                                                />
                                                <Typography
                                                  style={{
                                                    display: "none",
                                                    textAlign: "center",
                                                    width: "100%",
                                                    color: "#888",
                                                  }}
                                                >
                                                  Not Available
                                                </Typography>
                                              </>
                                            ) : messageType === "video" ? (
                                              <Box
                                                display="flex"
                                                alignItems="center"
                                                margin={"10px 0px 0px 0px"}
                                                style={{
                                                  width: "100%",

                                                  maxWidth: "100%",
                                                  padding: "10px",
                                                  border: "1px solid #ccc",
                                                  borderRadius: "8px",
                                                  backgroundColor: "#f9f9f9", // Message box styling
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    width: "100%",
                                                    cursor: "pointer",
                                                    color: "#007bff",
                                                    textDecoration: "underline",
                                                  }}
                                                  onClick={() => {
                                                    openViewModel(message.message,'video',name);
                                                  }}
                                                >
                                                  <VideoThumbnail
                                                    videoUrl={message.message}
                                                    thumbnailHandler={(thumbnail) => {
                                                      setLoading(false);
                                                    }}
                                                  />
                                                  {!loading && (
                                                    <div
                                                      style={{
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%",
                                                        transform:
                                                          "translate(-50%, -50%)",
                                                        cursor: "pointer",
                                                        width: "48px",
                                                        height: "48px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        backgroundColor:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        borderRadius: "50%",
                                                      }}
                                                      onClick={() =>
                                                        openViewModel(message.message,'video',name)
                                                      }
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="white"
                                                        width="24"
                                                        height="24"
                                                      >
                                                        <path d="M8 5v14l11-7z" />
                                                      </svg>
                                                    </div>
                                                  )}
                                                </Typography>
                                              </Box>
                                            ) : (
                                              <Typography 
                                                textAlign={"end"} color={"#000"}
                                                onClick={() => {
                                                  openViewModel(message.message,'file',name);
                                                }}
                                                sx={{
                                                  cursor:"pointer",
                                                  overflow:"hidden",
                                                  textOverflow:"ellipsis",
                                                  whiteSpace:"nowrap"
                                                }}
                                              >
                                                {name}
                                              </Typography>
                                            )}
                                          </Box>
                                        )}
                                        <Typography
                                          variant="caption"
                                          sx={{ textAlign: "left", marginLeft: "10px" }}
                                        >
                                          {formatDate(message.createdAt)}
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })
                  ) : (
                    <Typography>No Message</Typography>
                  )}
                </Box>
              }
              <div ref={chatEndRef} />
            </>
          }

        </Grid>

        {userMessagePlanType != 0 && !isThreadBlocked  && 
          <>
            { userMessagePlanType === 1 ?
              <></>
            :
              <Grid item xs={12} className="chat_footer" sx={{position:"relative"}}>
                  {showFileUploadStatus &&
                    <Grid 
                      container 
                      sx={{
                        position:'absolute',
                        background:'#fff',
                        top:'-70px',
                        borderBottom:'1px solid #f0f2f5'
                      }}
                    >
                      <Grid item xs={12} md={10.6} pl={"12px"} m={"10px 0px"}>
                        <Box>
                          <Grid container alignItems={"center"}>
                            <Grid item xs={2} md={1}>
                              <InsertDriveFileIcon  sx={{height:"55px",width:"55px",fill:"#6e6f70"}}/>
                            </Grid>
                            <Grid item xs={9} md={11}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Box>
                                    <Typography fontSize={"13px"}>{fileName}</Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} mb={0.3}>
                                  <Box>
                                    <Typography fontSize={"13px"} color={"#2a88c3"}>Uploading...</Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={12}>
                                  <LinearProgressWithLabel sx={{borderRadius:"10px"}} value={progress} />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>        
                        </Box>
                      </Grid>
                    </Grid>
                  }
                <Box p={2}>
                  <Grid container alignItems={"center"}>
                    {receiverProfile.deletedAccount ?
                    (<Typography sx={{width: '100%', textAlign: 'center', py: 1}}>
                      {t("deletedUser")}
                    </Typography>)
                      : (
                      <>
                        <Grid item xs={9} className="chat_input_container">
                          <InputEmoji
                            value={message}
                            onChange={messageChangeHandler}
                            cleanOnEnter
                            onEnter={handleKeyDown}
                            placeholder="Type a message"
                            />
                        </Grid>
                        <Grid item xs={1} sx={{ ml: { xs: 0, md: 2 }, textAlign: "left" }} textAlign="left">
                          <Box sx={{ cursor: "pointer" }} onClick={fileUpload}>
                            <AddIcon />
                          </Box>
                        </Grid>
                        <Grid item xs={1} ml={0}>
                          <Box
                            sx={{
                              background: "#1976d2",
                              cursor: "pointer",
                              borderRadius: "50%",
                              height: "36px",
                              width: "36px",
                            }}
                            >
                            <TelegramIcon
                              onClick={messageSendHandler}
                              sx={{
                                position: "relative",
                                top: "5px",
                                left: "3px",
                                color: "#fff",
                              }}
                              />
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </Grid>
            } 
          </>
        }

        <AlertElem isAlertMessage={showFileUploadError} alertMessage={errorMessage} alertMessageType={alertMessageType} onClose={()=>{onClose()}}/>
      </Grid>
      <CuModel open={showUserBlockModel} onClose={()=>hideUserBlockModelHandler()}>
        <Grid container sx={{p:"20px",justifyContent:"center"}}>
          <Grid item xs={12} textAlign={"center"} p={"20px 0px"}>
            <Typography>Are you sure want to Hide/Block a User</Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container textAlign={"center"}>
              <Grid item xs={6}>
                <Button  className="hide_block_btn" sx={{background:"#2a88c3",color:"#fff"}} onClick={()=>hideUserBlockModelHandler()}>No</Button>
              </Grid>
              <Grid item xs={6}>
                <Button  onClick={()=>userBlockModelHandler()} className="hide_block_btn" sx={{background:"#2a88c3",color:"#fff"}}>Yes</Button>
              </Grid>
            </Grid>
            </Grid>
        </Grid>
      </CuModel>
    </>
  );
}
