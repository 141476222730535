import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Utils from "../../utils/Utils";
import {useNavigate} from 'react-router-dom';
const { serverURL } = Utils;

const PaypalPayment = ({planData}) => {

        const navigate          =  useNavigate()

        const approveHandler = async(approveData) =>{
            const {paymentID} = approveData 
            const res = await fetch(`${serverURL}/paypal/capture`, {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                credentials: "include",
                body:JSON.stringify({
                    paymentId:paymentID,
                    ...planData
                })
            });
            const data_ = await res.json();
            const {code} =  data_
            if (code === 200){
                navigate(`/messages?session_id=${paymentID}`)
            }
        }

        const cancelHandler = () =>{
            navigate('/cancel')
        }
        
        const createOrderHandler = async() =>{
            const res = await fetch(`${serverURL}/paypal/payment`, {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                credentials: "include",
                body:JSON.stringify({...planData})
            });
            const data_ = await res.json();
            const  {code,data} = data_
            if (code === 200){
                const  {id} = data
                return id
            }
        }

        const errorHandler = (error) =>{
            navigate('/cancel')
        }

        const initialOptions = {
            clientId: "AUdkemv5BCWATbwYOfDOtpih7Is6ZMUFUaVe06AJJhcHcl4ZRv85CRjlSAGc5faQJFxDjhViSLvgUiQG",
            currency: planData.currency,
        };
        return (
            <div>
                <PayPalScriptProvider options={initialOptions}>
                    <PayPalButtons 
                        onApprove={approveHandler}
                        onCancel={cancelHandler}
                        createOrder={createOrderHandler}
                        fundingSource={"paypal"}
                        onError={errorHandler}
                    />
                </PayPalScriptProvider>
            </div>
        );
}

export default PaypalPayment;