import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Zoom from "@mui/material/Zoom";
import { useTranslation } from "react-i18next";

const messagesAndSubscriptions = "messages-and-subscriptions";
const generalNotification = "general";

function TermsAndPrivacyModal({
  isTermsAndPrivacyModal,
  setIsTermsAndPrivacyModal,
  handleRegistration,
  data,
  setData,
}) {
  const { t } = useTranslation(["terms-and-privacy"]);
  const isNotifyForMessage = data.emailPreferences.includes(
    messagesAndSubscriptions
  );
  const isGeneralNotification =
    data.emailPreferences.includes(generalNotification);
  const setIsNotifyForMessage = (e) => {
    setData((prev) => {
      let emailPreferences = [...prev.emailPreferences];
      const isPresent = emailPreferences.includes(messagesAndSubscriptions);
      if (isPresent)
        emailPreferences = emailPreferences.filter(
          (item) => item !== messagesAndSubscriptions
        );
      else emailPreferences.push(messagesAndSubscriptions);
      return { ...prev, emailPreferences };
    });
  };
  const setIsGeneralNotification = (e) => {
    setData((prev) => {
      let emailPreferences = [...prev.emailPreferences];
      const isPresent = emailPreferences.includes(generalNotification);
      if (isPresent)
        emailPreferences = emailPreferences.filter(
          (item) => item !== generalNotification
        );
      else emailPreferences.push(generalNotification);
      return { ...prev, emailPreferences };
    });
  };
  return (
    <Modal
      aria-labelledby="terms-and-privacy"
      aria-describedby="View Terms And Privacy"
      open={isTermsAndPrivacyModal}
      onClose={() => setIsTermsAndPrivacyModal(false)}
      closeAfterTransition
      disableScrollLock
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 250,
        },
      }}
      sx={{
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem 0",
      }}
    >
      <Zoom in={isTermsAndPrivacyModal}>
        <Paper
          elevation={2}
          sx={{
            display: "block",
            zIndex: 1,
            top: 0,
            maxWidth: "600px",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 4,
            p: 3,
            minWidth: "280px",
            width: "100%",
            margin: "auto",
          }}
        >
          <Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Typography variant="h4" component="h2">
                {t("ykopTermsAndPrivacy")}
              </Typography>
              <IconButton
                onClick={() => setIsTermsAndPrivacyModal(false)}
                sx={{ ml: "auto" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography sx={{ my: 2, overflow: "auto", height: "60dvh" }}>
              {t("termsAndPrivacyContent")}
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isNotifyForMessage}
                    onChange={setIsNotifyForMessage}
                  />
                }
                label={t("notifyForMessageAndSubscribedContent")}
                sx={{ color: "red" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isGeneralNotification}
                    onChange={setIsGeneralNotification}
                  />
                }
                label={t("sendGeneralNotification")}
                sx={{ color: "red" }}
              />
            </FormGroup>
            <Typography sx={{ my: 2, fontWeight: "bold" }}>
              {t("termsAndPrivacyDisclaimer")}
            </Typography>
            <Box sx={{ textAlign: "center" }}>
              <Button onClick={handleRegistration} variant="contained">
                {t("IAcceptTheTermsAndPrivacy")}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Zoom>
    </Modal>
  );
}

export default TermsAndPrivacyModal;
