import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Utils from "../utils/Utils";

const { serverURL } = Utils;

const initialState = {
    plans: [],
    loader:true,
    status:"success"
};


export const plan = createAsyncThunk(
  "plan/all",
  async () => {
    const response = await fetch(`${serverURL}/plan/all/`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const data = await response.json();
    return data ;
  }
);


export const planSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    setPlans: (state, action) => {
      state.plans = action.payload.plans;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(plan.fulfilled, (state, action) => {
      state.plans = action.payload.data;
      state.status = "success";
    });
    builder.addCase(plan.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export default planSlice.reducer;
export const { setPlans } = planSlice.actions;
