import React, { useEffect, useState,useCallback } from "react";
import { Button ,Grid,Box,TextField, Typography,Paper,Skeleton } from "@mui/material";
import {plan} from '../../redux/planSlice'
import { useDispatch,useSelector } from "react-redux";
import CuModel from '../../components/CuModel'
import { loadStripe } from '@stripe/stripe-js';
import Utils from "../../utils/Utils";
import PaypalPayment from "./PaypalPayment";
const { serverURL } = Utils;
const stripePromise = loadStripe('pk_test_51N8ck0SG2hkZqtEobEYJ3EBmPEqnnaEvwi5yWklN7yYLjozHD91mSxPvWbdkPc5Buj3Uo9cFakmYcNqHaAlIWn6R00Na1dPKhp');

const Plans = () =>{
    const dispatch = useDispatch()
    const data = useSelector((state)=>state.planSlice.plans)
    const [skeletonLoader,setSkeletonLoader]    = useState(true)
    const [planData,setPlanData]                = useState([])
    const [upSellplanData,setUpSellPlanData]    = useState([])
    const [selectedPlan,setselectedPlan]        = useState({})
    const [upSellSelectedPlan,setUpSellselectedPlan]  = useState({})
    const [showPromoCode,setShowPromoCode]      = useState(true)
    const [promoCode,setPromoCode]              = useState('')
    const [showUpSellPlanModel,setShowUpSellPlanModel] = useState(false)
    const [promoCodeError,setPromoCodeError]    = useState(false)
    const [paymentModel,setPaymentModel]        = useState(false)
    const [finalPlanSelected,setFinalPlanSelected]        = useState({})


    useEffect(()=>{
        (async()=>{
            setSkeletonLoader(true)
            await dispatch(plan()).unwrap();
            setSkeletonLoader(false)
          
        })()
    },[])

    useEffect(()=>{
        planDataCall_()
    },[data])

    const planDataCall_ = ()=>{
        let notUpSellPlan = data.filter((plan)=>(plan.upsell === 0))
        let upSellPlan = data.filter((plan)=>(plan.upsell === 1))
        const promoCodeCount = data.filter(plan => plan.hasOwnProperty("promoCode")).length;
        if (promoCodeCount>0){
            setShowPromoCode(true)
        } else {
            setShowPromoCode(false)
        }
        setPlanData(notUpSellPlan)
        setUpSellPlanData(upSellPlan)
    }
    
    const filterPromoCodeHandler = () =>{
        setPromoCodeError(false)
        if (promoCode === ''){
            let notUpSellPlan = data.filter((plan)=>(plan.upsell === 0))
            setPlanData(notUpSellPlan)
        } else {
            let filterPromoCodePlan = data.filter((plan)=>plan?.promoCode === promoCode )
            setPlanData(filterPromoCodePlan)
            if (filterPromoCodePlan.length === 0){
                setPromoCodeError(true)
            }   
        }
    }

    useEffect(()=>{
        if (promoCode === ''){
            let notUpSellPlan = data.filter((plan)=>(plan.upsell === 0))
            setPlanData(notUpSellPlan)
        }
    },[promoCode])

    const selectPlanHandler = async(plan) =>{
        setselectedPlan(plan)
        setUpSellselectedPlan({})
        if (!promoCode){
            setShowUpSellPlanModel(true)
        } else {
            setFinalPlanSelected(plan)
            setPaymentModel(true)
        }
    }

    const skipPromoCodeHandler = () =>{
        if (!promoCodeError){
            setShowPromoCode(false)
        }
        
    }

    const addedPromoCodeHandler = (e) =>{
        setPromoCodeError(false)
        const {value} = e.target
        setPromoCode(value)
    }

    const showUpSellPlanModelHandler = () =>{
        setShowUpSellPlanModel(false)
        setUpSellselectedPlan({})
        setselectedPlan({})
    }

    const selectUpSellPlanHandler = (upSellPlan) =>{
        setUpSellselectedPlan(upSellPlan)
    }

    const upSellPlanSkipHandler = async() =>{
        setFinalPlanSelected(selectedPlan)
        setPaymentModel(true)
        setShowUpSellPlanModel(false)
    }

    const upSellPlanNextHandler = async() =>{
        setFinalPlanSelected(upSellSelectedPlan)
        setShowUpSellPlanModel(false)
        setPaymentModel(true)
    }

    const stripePayHandler = async()=>{
        let plan_ = {...finalPlanSelected}
        plan_.value = Math.round(finalPlanSelected.value * 100)
        const stripe = await stripePromise;
        const res = await fetch(`${serverURL}/plan/checkout-session`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(plan_)
        });
        const { id } = await res.json();
        const { error } = await stripe.redirectToCheckout({ sessionId: id });
        if (error) {
            console.error("Error during redirect:", error);
        }
    }

    const hidePaymentModelHandler = () =>{
        setPaymentModel(false)
        setFinalPlanSelected({})
        setselectedPlan({})
        setUpSellselectedPlan({})
    }

    return(
        <>
            {
                skeletonLoader ?
                <Grid container display={"block"} m={5}>
                    <Grid item xs={12} md={6} >
                        <Grid container>
                            <Grid item xs={7}>
                                <Skeleton animation="pulse" variant="rounded" width="100%" height={60} />
                            </Grid>
                            <Grid item xs={4} ml={1}>
                                <Skeleton animation="pulse" variant="rounded" width="100%" height={60} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} mt={5}>
                        <Grid container spacing={1}>
                            {
                                [1,2,3,4].map((item,index)=>{
                                    return(
                                        <Grid item key={item} xs={3}>
                                            <Paper>
                                                <Grid container p={3}>
                                                    <Grid item xs={12}>
                                                        <Skeleton animation="pulse" variant="rounded" width="100%" height={20} />
                                                    </Grid>
                                                    <Grid item xs={12} mt={1}>
                                                        <Skeleton animation="pulse" variant="rounded" width="50%" height={20} />
                                                    </Grid>
                                                    <Grid item xs={12} mt={1}>
                                                        <Skeleton animation="pulse" variant="rounded" width="70%" height={15} />
                                                    </Grid>
                                                    <Grid item xs={12} mt={4}>
                                                        <Skeleton animation="pulse" variant="rounded" width="70%" height={40} />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    )
                                })
                            }
                            
                        </Grid>
                    </Grid>
                </Grid>
            :
                <Grid container display={"block"} m={5}>
                    {showPromoCode &&
                        <Grid item xs={12} md={8}>
                            <Grid container>
                                <Grid item xs={5}>
                                    <TextField value={promoCode} onChange={(e)=>addedPromoCodeHandler(e)} fullWidth id="outlined-basic" label="Promo code" variant="outlined" />
                                </Grid>
                                <Grid item xs={3} ml={1}>
                                    <Button onClick={(()=>{filterPromoCodeHandler()})} fullWidth sx={{height:"100%"}} variant="contained">Apply</Button>
                                </Grid>
                                <Grid item xs={3} ml={1}>
                                    <Button  onClick={(()=>{skipPromoCodeHandler()})} fullWidth sx={{height:"100%"}} variant="contained">Skip</Button>
                                </Grid>
                                {promoCodeError &&
                                    <Grid item xs={12} sx={{mt:"10px"}}>
                                        <Typography color={"red"} >Promo code not validate.</Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12} mt={5}>
                        <Grid container spacing={1}>
                            {
                                planData.map((plan,index)=>{
                                    return(
                                        <Grid key={index} item xs={13} md={3}>
                                            <Paper>
                                                <Grid container p={3}>
                                                    <Grid item xs={12}>
                                                        <Typography fontWeight={500} variant="h5">{plan.description}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} mt={1}>
                                                        <Typography>
                                                            <Typography variant="h6">
                                                                {plan.currencySymbol} {plan.value}
                                                            </Typography>
                                                        </Typography>
                                                    </Grid>
                                                    {/* <Grid item xs={12} mt={1}>
                                                        <Typography>
                                                            <Typography component={"span"} mr={1}>
                                                                Chat Duration:-
                                                            </Typography>
                                                            <Typography component={"span"}>
                                                                {plan.duration} Days
                                                            </Typography>
                                                        </Typography>
                                                    </Grid> */}
                                                    <Grid item xs={12} mt={4}>
                                                        <Button onClick={()=>{selectPlanHandler(plan)}} fullWidth variant="outlined">
                                                            {
                                                                plan._id === selectedPlan._id ?
                                                                "Selected":"Select"
                                                            }
                                                            
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    )
                                })  
                            }
                        </Grid>
                    </Grid>
                </Grid>
            }
            <CuModel onClose={()=>showUpSellPlanModelHandler()} open={showUpSellPlanModel}>
                <Grid container sx={{p:"20px"}} justifyContent={"center"}>
                    <Grid item xs={12}>Upsell plan</Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={1}>
                                {
                                    upSellplanData.map((plan,index)=>{
                                        return(
                                            <Grid key={index} item xs={12}>
                                                <Paper>
                                                    <Grid container p={3}>
                                                        <Grid item xs={12}>
                                                            <Typography fontWeight={500} variant="h5">{plan.description}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} mt={1}>
                                                            <Typography>
                                                                <Typography variant="h6">
                                                                    {plan.currencySymbol} {plan.value}
                                                                </Typography>
                                                            </Typography>
                                                        </Grid>
                                                        {/* <Grid item xs={12} mt={1}>
                                                            <Typography>
                                                                <Typography component={"span"} mr={1}>
                                                                    Chat Duration:-
                                                                </Typography>
                                                                <Typography component={"span"}>
                                                                    {plan.duration} Days
                                                                </Typography>
                                                            </Typography>
                                                        </Grid> */}
                                                        <Grid item xs={12} mt={4}>
                                                            <Button onClick={()=>{selectUpSellPlanHandler(plan)}} fullWidth variant="outlined">
                                                                {
                                                                    plan._id === upSellSelectedPlan._id ?"Selected":"Select"
                                                                }
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                        )
                                    })  
                                }
                        </Grid>
                    </Grid>
                    <Grid item xs={7} mt={4} textAlign={"center"}>
                        <Button disabled={(Object.keys(upSellSelectedPlan).length === 0)?true:false} onClick={()=>upSellPlanNextHandler()} sx={{marginRight:"10px"}} variant="contained">Next</Button>
                        {
                            Object.keys(upSellSelectedPlan).length === 0 &&
                            <Button onClick={()=>upSellPlanSkipHandler()} variant="contained">Skip</Button>
                        }
                    </Grid>
                </Grid>
            </CuModel>
            <CuModel onClose={()=>hidePaymentModelHandler()} open={paymentModel}>
                <Grid container p={"40px"} width={"500px"}>
                    <Grid item xs={12} textAlign={"center"} mb={4}>
                        <Typography variant="h6">Pay Now</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Box sx={{
                                        backgroundColor:"#533afd",
                                        textAlign:'center',
                                        padding: '6px',
                                        borderRadius: '5px',
                                        color:'#fff',
                                        cursor:"pointer"
                                    }}
                                    onClick={()=>{stripePayHandler()}}   
                                >
                                    Stripe
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box>
                                    <PaypalPayment  planData={finalPlanSelected} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CuModel>
        </>
    )
}

export default Plans