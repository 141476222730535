import { configureStore } from '@reduxjs/toolkit';
import AuthSlice from './authSlice';
import CommentsSlice from './commentsSlice';
import EventCommentsSlice from './eventCommentsSlice';
import EventsSlice from './eventsSlice';
import FeedsSlice from './feedsSlice';
import nodesSlice from './nodesSlice';
import PostsSlice from './postsSlice';
import UserImagesSlice from './userImagesSlice';
import conversationSlice  from './conversationSlice'
import messageSlice from './messageSlice'
import liveUserSlice from './liveUserSlice'
import messageReadSlice from './messageRead'
import notificationSlice from './notificationCounter'
import mobileChatSlice from './mobileChatSlice'
import messageBarSlice from './messageBarSlice'
import loaderSlice from './loaderSlice'
import planSlice from './planSlice'

export default configureStore({
    reducer: {
        auth: AuthSlice,
        nodes: nodesSlice,
        posts: PostsSlice,
        events: EventsSlice,
        comments: CommentsSlice,
        eventComments: EventCommentsSlice,
        feeds: FeedsSlice,
        userImages: UserImagesSlice,
        conversation:conversationSlice,
        message:messageSlice,
        liveUser:liveUserSlice,
        messageRead:messageReadSlice,
        notification:notificationSlice,
        mobileChatSlice,
        messageBarSlice,
        loaderSlice,
        planSlice

    },
    devTools: process.env.NODE_ENV !== 'production',
});
