import {createSlice } from "@reduxjs/toolkit";

const initialState = {
    status: true,
};


export const loaderSlice = createSlice({
  name: "loader_status",
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload.status;
    },
  },
});

export default loaderSlice.reducer;
export const { setStatus } = loaderSlice.actions;
